@font-face {
  font-family: Articulat;
  src: url("ArticulatCF-Normal.de09ee4a.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(0 163 255 / var(--tw-bg-opacity));
  border-radius: 10px;
}

body::-webkit-scrollbar-track {
  --tw-bg-opacity: 1;
  background-color: rgb(22 23 23 / var(--tw-bg-opacity));
}

::selection {
  background-color: #00ccff80;
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: 1.6mm;
  font-family: Articulat, sans-serif;
}

p {
  letter-spacing: .1em;
}

a {
  filter: none;
  cursor: pointer;
  display: block;
  transform: scale(1);
}

a:hover {
  filter: drop-shadow(0 .35px #0cf) drop-shadow(0 .7px #00a3ff) drop-shadow(0 1.05px #007aff) drop-shadow(0 1.4px #0052ff) drop-shadow(0 1.75px #00f) drop-shadow(0 2.1px #0700c4);
  transition: filter .15s ease-in-out;
}

a:active {
  transform: scale(1.04);
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.fixed {
  position: fixed;
}

.\!absolute {
  position: absolute !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.-left-\[40px\] {
  left: -40px;
}

.-right-\[100px\] {
  right: -100px;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-\[340px\] {
  left: 340px;
}

.left-\[35\%\] {
  left: 35%;
}

.left-\[385px\] {
  left: 385px;
}

.left-\[415px\] {
  left: 415px;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-\[1195px\] {
  top: 1195px;
}

.top-\[1330px\] {
  top: 1330px;
}

.top-\[1580px\] {
  top: 1580px;
}

.top-\[370px\] {
  top: 370px;
}

.top-\[540px\] {
  top: 540px;
}

.top-\[620px\] {
  top: 620px;
}

.top-\[800px\] {
  top: 800px;
}

.-z-10 {
  z-index: -10;
}

.z-10 {
  z-index: 10;
}

.row-start-1 {
  grid-row-start: 1;
}

.float-right {
  float: right;
}

.\!-m-px {
  margin: -1px !important;
}

.m-0 {
  margin: 0;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-\[20vh\] {
  margin-top: 20vh;
}

.mt-\[30vh\] {
  margin-top: 30vh;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.\!h-px {
  height: 1px !important;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-\[300px\] {
  height: 300px;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-max {
  height: max-content;
}

.h-screen {
  height: 100vh;
}

.max-h-\[300px\] {
  max-height: 300px;
}

.\!w-px {
  width: 1px !important;
}

.w-8 {
  width: 2rem;
}

.w-\[10\%\] {
  width: 10%;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[14\%\] {
  width: 14%;
}

.w-\[18\%\] {
  width: 18%;
}

.w-\[22\%\] {
  width: 22%;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[70\%\] {
  width: 70%;
}

.w-\[80\%\] {
  width: 80%;
}

.w-\[800px\] {
  width: 800px;
}

.w-\[9\%\] {
  width: 9%;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-md {
  max-width: 768px;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-fade {
  animation: 1s both fade;
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-up {
  animation: 1s both fade-up;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.list-none {
  list-style-type: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-12 {
  gap: 3rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-16 {
  column-gap: 4rem;
}

.gap-y-16 {
  row-gap: 4rem;
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.\!overflow-hidden {
  overflow: hidden !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overscroll-none {
  overscroll-behavior: none;
}

.\!whitespace-nowrap {
  white-space: nowrap !important;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.\!border-0 {
  border-width: 0 !important;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-current {
  border-color: currentColor;
}

.border-r-transparent {
  border-right-color: #0000;
}

.bg-background {
  --tw-bg-opacity: 1;
  background-color: rgb(22 23 23 / var(--tw-bg-opacity));
}

.bg-brand-2 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 163 255 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-none {
  background-image: none;
}

.\!p-0 {
  padding: 0 !important;
}

.p-0 {
  padding: 0;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.text-center {
  text-align: center;
}

.align-\[-0\.125em\] {
  vertical-align: -.125em;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.italic {
  font-style: italic;
}

.tracking-\[\.1em\] {
  letter-spacing: .1em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-background {
  --tw-text-opacity: 1;
  color: rgb(22 23 23 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.animate-delay-\[1s\] {
  animation-delay: 1s;
}

.animate-delay-\[2s\] {
  animation-delay: 2s;
}

.animate-fill-backwards {
  animation-fill-mode: backwards;
}

.animate-ease-in {
  animation-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.\!\[clip\:rect\(0\,0\,0\,0\)\] {
  clip: rect(0, 0, 0, 0) !important;
}

.\[grid-template-columns\:_auto_1fr\] {
  grid-template-columns: auto 1fr;
}

.\[grid-template-rows\:_auto_auto\] {
  grid-template-rows: auto auto;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:-left-4:before {
  content: var(--tw-content);
  left: -1rem;
}

.before\:left-\[-1\.15em\]:before {
  content: var(--tw-content);
  left: -1.15em;
}

.before\:hidden:before {
  content: var(--tw-content);
  display: none;
}

.before\:h-full:before {
  content: var(--tw-content);
  height: 100%;
}

.before\:w-1:before {
  content: var(--tw-content);
  width: .25rem;
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:bg-brand-2:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 163 255 / var(--tw-bg-opacity));
}

.before\:content-\[\'\|\'\]:before {
  --tw-content: "|";
  content: var(--tw-content);
}

.before\:content-\[attr\(before\)\]:before {
  --tw-content: attr(before);
  content: var(--tw-content);
}

.after\:content-\[attr\(after\)\]:after {
  --tw-content: attr(after);
  content: var(--tw-content);
}

.hover\:drop-shadow-brand:hover {
  filter: drop-shadow(0 .35px #0cf) drop-shadow(0 .7px #00a3ff) drop-shadow(0 1.05px #007aff) drop-shadow(0 1.4px #0052ff) drop-shadow(0 1.75px #00f) drop-shadow(0 2.1px #0700c4);
  transition: filter .15s ease-in-out;
}

.active\:scale-\[1\.04\]:active {
  --tw-scale-x: 1.04;
  --tw-scale-y: 1.04;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[hidden\=\'true\'\]\:hidden[data-hidden="true"], .group[data-open-mobile="false"] .group-data-\[open-mobile\=\'false\'\]\:hidden, .group[data-open-mobile="true"] .group-data-\[open-mobile\=\'true\'\]\:hidden {
  display: none;
}

@media (prefers-reduced-motion: reduce) {
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .motion-reduce\:animate-\[spin_1\.5s_linear_infinite\] {
    animation: 1.5s linear infinite spin;
  }
}

@media (width >= 640px) {
  .sm\:w-\[75\%\] {
    width: 75%;
  }
}

@media (width >= 768px) {
  .md\:col-start-2 {
    grid-column-start: 2;
  }

  .md\:-mt-16 {
    margin-top: -4rem;
  }

  .md\:-mt-8 {
    margin-top: -2rem;
  }

  .md\:w-\[100\%\] {
    width: 100%;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-\[2fr_5fr\] {
    grid-template-columns: 2fr 5fr;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (width >= 1024px) {
  .lg\:-mt-16 {
    margin-top: -4rem;
  }

  .lg\:-mt-32 {
    margin-top: -8rem;
  }

  .lg\:h-\[400px\] {
    height: 400px;
  }

  .lg\:max-h-\[400px\] {
    max-height: 400px;
  }

  .lg\:w-\[60\%\] {
    width: 60%;
  }
}

@media (width >= 1280px) {
  .xl\:absolute {
    position: absolute;
  }

  .xl\:right-0 {
    right: 0;
  }

  .xl\:mr-0 {
    margin-right: 0;
  }

  .xl\:block {
    display: block;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:gap-8 {
    gap: 2rem;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:before\:block:before {
    content: var(--tw-content);
    display: block;
  }

  .group[data-open-mobile="false"] .xl\:group-data-\[open-mobile\=\'false\'\]\:flex {
    display: flex;
  }
}

/*# sourceMappingURL=index.5dd754f4.css.map */
